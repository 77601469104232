<template>
   <div class="login">
      <div class="container-fluid">
         <div class="row no-gutter">
            <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
            <div class="col-md-8 col-lg-6">
               <div class="login d-flex align-items-center py-5">
                  <div class="container">
                     <div class="row">
                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5">
                           <h3 class="login-heading mb-4">Welcome back!</h3>
                           <!-- {{ getUserInfo }} -->
                           
                           <el-form
                              ref="ruleFormRef"
                              :model="ruleForm"
                              label-width="120px"
                              class="demo-ruleForm"
                              status-icon
                              label-position="top"
                           >
                              <div class="form-label-group">
                                 <input type="input" :disabled="disabled" v-model="ruleForm.email" @change="inputemail" id="inputEmail" autocomplete="off" class="form-control" placeholder="Email Address  or User Name">
                                 <label for="inputEmail" :class="errormassing.email?'errorlabel':''">Email Address  or User Name</label>
                                 <div class="el-form-item__error" v-show="errormassing.email">{{ errormassing.email }}</div>
                              </div>
                              <div class="form-label-group">
                                 <input type="password" :disabled="disabled" v-model="ruleForm.password" @change="inputpwd" id="inputPassword" autocomplete="off" class="form-control" placeholder="Password">
                                 <label for="inputPassword" :class="errormassing.password?'errorlabel':''">Password</label>
                                 <div class="el-form-item__error" v-show="errormassing.password">{{ errormassing.password }}</div>
                              </div>
                              <div class="custom-control custom-checkbox mb-3">
                                 <input type="checkbox" :disabled="disabled" v-model="ruleForm.rememberpwd" class="custom-control-input" id="customCheck1">
                                 <label class="custom-control-label" for="customCheck1">Remember password</label>
                              </div>
                              <div class="custom-control custom-checkbox mb-3">
                                 <input type="checkbox" :disabled="disabled" v-model="ruleForm.check" class="custom-control-input" id="customCheck2">
                                 <label class="custom-control-label" for="customCheck2">I agree to the <router-link to="/service" target ="_blank">terms</router-link> and <router-link to="/privacy" target ="_blank">privacy policy</router-link>.</label>
                                 <div class="el-form-item__error" v-show="errormassing.check">{{ errormassing.check }}</div>
                              </div>
                              <el-button class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 btn-new" :loading="loading" :disabled="disabled" @click="submitForm()">
                                  Sign in
                              </el-button>
                              <!-- <button class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" :disabled="disabled" @click="submitForm">
                                 Sign in
                              </button> -->
                              <div class="text-center pt-3">
                                 Don’t have an account? 
                                 <router-link to="/register" class="font-weight-bold">Sign Up</router-link><br/>
                                 <router-link to="/retrievePassword" class="font-weight-bold">Retrieve Password?</router-link>
                              </div>
                           </el-form>
                           <hr class="my-4">
                           <p class="text-center">LOGIN WITH</p>
                           <div class="row">
                              <div class="col pr-2 login-three">
                                 <!-- <button @click="handleFirstClick" class="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Google</button> -->
                                 <Google ref="targetElement" v-on:customEvent="googleres"></Google>
                                 <Facebook ref="targetElement2" v-on:customEvent="googleres"></Facebook>
                              </div>
                              <!-- <div class="col pl-2">
                                 <button class="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Facebook</button>
                              </div> -->
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {checkIn} from '@/network/user.js'
import Google from '@/components/Google.vue';
import Facebook from '@/components/Facebook.vue';
import { useRouter } from 'vue-router';
export default {
   name: 'LoginView',
   data() {
      return {
         loading: false,
         disabled: false,
         errormassing:{
            email: '',
            password: '',
            check: ''
         },
         ruleForm:{
            email: '',
            password: '',
            rememberpwd: false,
            check: false
         },
         rules:{
           
         }
      }
   },
   components: {
      Google,
      Facebook
   },
   beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
   },
   mounted(){
      let email = localStorage.getItem("email");
      let password = localStorage.getItem("password");
      if (email!= '' && password!='') {
         this.ruleForm.email = email
         this.ruleForm.password = password
         this.ruleForm.rememberpwd = true
      }
   },
   computed:{
      
   },
   methods: {
      inputemail(){
         // var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
         if(this.ruleForm.email==''){
            this.errormassing.email='Please enter a mailbox or user name.'
         }
         // else if(!emailReg.test(this.ruleForm.email)){
         //    this.errormassing.email='The mailbox format is incorrect.'
         // }
         else{
            this.errormassing.email=''
         }

      },
      inputpwd(){
         var pwdReg = /^(?!.*[&<>"'\n\r]).{8,12}$/
         if(this.ruleForm.password==''){
            this.errormassing.password='Please enter the password.'
         }else if(!pwdReg.test(this.ruleForm.password)){
            this.errormassing.password='Password length is 8-12 digits.'
         }else{
            this.errormassing.password=''
         }
      },
      inputcheck(){
         if(this.ruleForm.check==false){
            this.errormassing.check= this.$t('login.cont1')
         }else{
            this.errormassing.check=''
         }
      },
      submitForm() {
         this.inputcheck()
         if(this.ruleForm.email=='' || this.ruleForm.password=='' || this.ruleForm.check==false){
            this.inputemail();
            this.inputpwd();
         }else{
            this.loading = true
            this.disabled = true

            checkIn('login', this.ruleForm.email, this.ruleForm.password).then(res => {
               if(res){
                  if(res.code == 200 || res.code == 1){
                     if(this.ruleForm.rememberpwd){
                        localStorage.setItem("email", this.ruleForm.email);
                        localStorage.setItem("password", this.ruleForm.password);
                     }else{
                        localStorage.removeItem("email");
                        localStorage.removeItem("password");
                     }
                     this.$store.dispatch('getUserInfo',res.data.userInfo)
                     this.$message.success(res.msg);
                     setTimeout(() => {
                        let toPath = this.prevRoute ? this.prevRoute.fullPath : '/';
                        this.$router.push(toPath);
                     }, 3000);
                  }else{
                     this.loading = false
                     this.disabled = false
                  }
               }else{
                  this.loading = false
                  this.disabled = false
               }
            })
         }
        
      },
      googleres(data) {
         setTimeout(() => {
            let toPath = this.prevRoute ? this.prevRoute.path : '/';
            this.$router.push(toPath);
         }, 3000);
      }
   }
}
</script>

<style scoped>
.login{
   background-color: #272727 !important;
}
.errorlabel{
   border-bottom: 1px solid #ff3008  !important;
}
.login-three{
   text-align:center;
}

</style>
