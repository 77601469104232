import { request } from "./request";

export function checkIn(tab,email,password,username,captcha){
    return request({
        url: '/api/user/checkIn',
        method: 'POST',
        // headers: {'showLoading': true},
        data: {
            tab,
            email,
            password,
            username,
            captcha
        }
    })
}

export function TripartiteCheck(type,email,name,voucher){
    return request({
        url: '/api/user/TripartiteCheck',
        method: 'POST',
        data: {
            type,
            email,
            name,
            voucher
        }
    })
}

export function logout(){
    return request({
        url: '/api/user/logout',
        method: 'POST',
        data: {
        }
    })
}

export function editUser(avatar, username, country,motto){
    return request({
        url: '/api/user/editUser',
        method: 'POST',
        data: {
            avatar,
            username,
            country,
            motto
        }
    })
}

export function userinfo(){
    return request({
        url: '/api/user/index',
        method: 'POST',
        data: {
            
        }
    })
}

export function dataRegion(){
    return request({
        url: '/api/ajax/dataRegion',
        params: {
        }
    })
}

export function modifyPassword(pwd, captcha){
    return request({
        url: '/api/user/modifyPassword',
        method: 'POST',
        data: {
            pwd, 
            captcha
        }
    })
}


export function retrievePassword(email, pwd, captcha){
    return request({
        url: '/api/user/retrievePassword',
        method: 'POST',
        data: {
            email,
            pwd, 
            captcha
        }
    })
}

export function emsSend(email, event, password){
    return request({
        url: '/api/ems/send',
        method: 'POST',
        data: {
            email, 
            event, 
            password
        }
    })
}

export function bindemail(email,captcha){
    return request({
        url: '/api/user/bindemail',
        method: 'POST',
        data: {
            email,
            captcha,
        }
    })
}

export function follow(fuid){
    return request({
        url: '/api/user/follow',
        method: 'POST',
        data: {
            fuid,
        }
    })
}

export function notice(type,page){
    return request({
        url: '/api/user/notice',
        params: {
            type,
            page
        }
    })
}

export function noticecont(){
    return request({
        url: '/api/user/noticecont',
        params: {
        }
    })
}
