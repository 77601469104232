<template>
    <div class="tabbar">
        <section class="breadcrumb-osahan mt-5 pt-5 pb-5 bg-dark position-relative text-center">
            <h1><slot name="title"></slot></h1>
         <h6 class="text-white-50"><slot name="con"></slot></h6>
      </section>
    </div>
</template>

<script>

export default {
    name: 'tabbar',
    data() {
       return{
        menu:''
       }
    },
    props: {

  },
//   watch: {
//     $route: {
//       handler: function(to, from){
//         this.path = to.path
//         let path = to.path
//         this.menu = path.substr(1)
//         // console.log(this.menu)
//         if(this.menu == '' || this.menu == 'home'){
//             this.menu = 'index'
//         }else if(this.menu.includes('listing') || this.menu.includes('search') || this.menu=='detail'){
//             this.menu = 'listing'
//         }else if(this.menu.includes('user')){
//             this.menu = 'user'
//         }else if(this.menu.includes('vlog/index') ){
//             this.menu = 'vlogindex'
//         }else if(this.menu.includes('vlog/blog') ){
//             this.menu = 'vlogblog'
//         }else if(this.menu.includes('trader')  || this.menu.includes('topbrokers')){
//             this.menu = 'topbrokers'
//             console.log(111);
//         }else{
//             this.menu = ''
//         }
//         // console.log(this.menu);
//         if(this.menu !=""){
//             $("#navbarNavDropdown .nav-item").removeClass('active');
//             $("."+this.menu).addClass('active');
//         }
//       },
//       deep: true
//     }

//   },
//     mounted() {
//         $(".navbar-nav .nav-item").mouseover(function () {
//             $(this).addClass("show");
//             $(this).children(".dropdown-menu").addClass("show");
//         }).mouseout(function () {
//             $(this).removeClass("show");
//             $(this).children(".dropdown-menu").removeClass("show");
//         });
        
//     },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
