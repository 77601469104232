<template>
   <div class="google">
      <GoogleLogin :callback="callback"></GoogleLogin>
   </div>
</template>

<script setup>
import { defineEmits } from 'vue'
import { decodeCredential } from 'vue3-google-login'
import { TripartiteCheck } from '@/network/user.js'
import { ElMessage } from 'element-plus'
import store from '@/store/index.js'
const emit = defineEmits(["alertSome"])
const callback = (response) => {
   // console.log(response);
   const userData = decodeCredential(response.credential)
   TripartiteCheck(1,userData.email, userData.name, userData.sub).then(res => {
      if (res) {
         if (res.code == 200 || res.code == 1) {
            store.dispatch('getUserInfo', res.data.userInfo)
            ElMessage({
               message: res.msg,
               type: 'success',
            })
            emit("customEvent", 1)
         } else {

         }
      } else {

      }
   })
   //   console.log("Handle the userData", userData)
}
</script>

