<template>
   <div class="register">
      <div class="container-fluid">
         <div class="row no-gutter">
            <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
            <div class="col-md-8 col-lg-6">
               <div class="login d-flex align-items-center py-4">
                  <div class="container">
                     <div class="row">
                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5">
                           <h3 class="login-heading mb-4">New Buddy!</h3>
                           <form>
                              <div class="form-label-group row">
                                 <div class="col-sm-6">
                                    <button type="button" @click="type=1" :class="type==1?'btn btn-outline-success':'btn btn-outline-secondary'">Individual</button>
                                 </div>
                                 <div class="col-sm-6">
                                    <button type="button" @click="type=2" :class="type==2?'btn btn-outline-success':'btn btn-outline-secondary'">Company</button>
                                 </div>
                              </div>
                              <div v-show="type==1">
                                 <div class="form-label-group">
                                    <input type="input" :disabled="disabled" v-model="ruleForm.email" @change="inputemail" id="inputEmail" autocomplete="off" class="form-control" placeholder="Email address">
                                    <label for="inputEmail" :class="errormassing.email?'errorlabel':''">Email address</label>
                                    <div class="el-form-item__error" v-show="errormassing.email">{{ errormassing.email }}</div>
                                 </div>
                                 <div class="form-label-group">
                                    <input type="input" :disabled="disabled" v-model="ruleForm.username" @change="inputname" id="inputName" autocomplete="off" class="form-control" placeholder="User Name">
                                    <label for="inputName" :class="errormassing.username?'errorlabel':''">User Name</label>
                                    <div class="el-form-item__error" v-show="errormassing.username">{{ errormassing.username }}</div>
                                 </div>
                                 <div class="form-label-group">
                                    <input type="password" :disabled="disabled" v-model="ruleForm.password" @change="inputpwd" id="inputPassword" autocomplete="off" class="form-control" placeholder="Password">
                                    <label for="inputPassword" :class="errormassing.password?'errorlabel':''">Password</label>
                                    <div class="el-form-item__error" v-show="errormassing.password">{{ errormassing.password }}</div>
                                 </div>
                                 <div class="code-item">
                                    <div class="form-label-group" style="width:220px">
                                       <input type="input" :disabled="disabled" v-model="ruleForm.code" @change="inputcode" id="inputCode" autocomplete="off" class="form-control" placeholder="Code">
                                       <label for="inputCode" :class="errormassing.code?'errorlabel':''">Captcha</label>
                                       <div class="el-form-item__error" v-show="errormassing.code">{{ errormassing.code }}</div>
                                    </div>
                                    <el-button class="btn btn-lg btn-outline-secondary btn-block btn-login text-uppercase font-weight-bold mb-2 btn-new" :loading="isSendingloading"  @click="sendCode" :disabled="isSending || countdown > 0">
                                       {{ isSending ? 'Sending' : countdown > 0 ? `${countdown} Send in seconds` : 'Send' }}
                                    </el-button>
                                 </div>
                                 <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" :disabled="disabled" v-model="ruleForm.check" class="custom-control-input" id="customCheck2">
                                    <label class="custom-control-label" for="customCheck2">I agree to the <router-link to="/service" target ="_blank">terms</router-link> and <router-link to="/privacy" target ="_blank">privacy policy</router-link>.</label>
                                    <div class="el-form-item__error" v-show="errormassing.check">{{ errormassing.check }}</div>
                                 </div>
                                 <el-button class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 btn-new" :loading="loading" :disabled="disabled" @click="submitForm()">
                                    Sign Up
                                 </el-button>
                                 <!-- <button class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" :disabled="disabled" @click="submitForm">
                                    Sign Up
                                 </button> -->
                              </div>
                              <div v-show="type==2">
                                 <p :v-show="type==2">coming soon</p>
                              </div>
                              
                              
                              <div class="text-center pt-3">
                                 Already have an Account? 
                                 <router-link to="/login" class="font-weight-bold">Sign In</router-link>
                              </div>
                           </form> 
                           <hr class="my-4">
                           <p class="text-center">LOGIN WITH</p>
                           <div class="row">
                              <div class="col pr-2 login-three">
                                 <!-- <button @click="handleFirstClick" class="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Google</button> -->
                                 <Google ref="targetElement" v-on:customEvent="googleres"></Google>
                                 <Facebook ref="targetElement2" v-on:customEvent="googleres"></Facebook>
                              </div>
                              <!-- <div class="col pl-2">
                                 <button class="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Facebook</button>
                              </div> -->
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {checkIn, emsSend} from '@/network/user.js'
import Google from '@/components/Google.vue';
import Facebook from '@/components/Facebook.vue';
export default {
   name: 'RegisterView',
   data() {
      return {
         loading: false,
         disabled: false,
         isSendingloading: false,
         isSending: false,
         countdown: 0,
         type: 1,
         classOn: 'btn btn-outline-success',
         classOff: 'btn btn-outline-secondary',
         errormassing:{
            email: '',
            username: '',
            password: '',
            code: '',
            check: ''
         },
         ruleForm:{
            email: '',
            username: '',
            password: '',
            code: '',
            rememberpwd: false,
            check: false
         },
         rules:{
           
         }
      }
   },
   components: {
      Google,
      Facebook
   },
   mounted() {


   },
   beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
   },
   methods: {
      inputemail(){
         var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
         if(this.ruleForm.email==''){
            this.errormassing.email='Please enter a mailbox.'
         }else if(!emailReg.test(this.ruleForm.email)){
            this.errormassing.email='The mailbox format is incorrect.'
         }else{
            this.errormassing.email=''
         }
      },
      inputname(){
         var nameReg = /^[a-zA-Z][a-zA-Z0-9_]{2,15}$/;
         if(this.ruleForm.username==''){
            this.errormassing.username='Please enter a user name.'
         }else if(!nameReg.test(this.ruleForm.username)){
            this.errormassing.username='Starting with a letter, the length of letters, numbers and underscores is 2 to 15.'
         }else{
            this.errormassing.username=''
         }
      },
      inputpwd(){
         var pwdReg = /^(?!.*[&<>"'\n\r]).{8,12}$/
         if(this.ruleForm.password==''){
            this.errormassing.password='Please enter the password.'
         }else if(!pwdReg.test(this.ruleForm.password)){
            this.errormassing.password='Password length is 8-12 digits.'
         }else{
            this.errormassing.password=''
         }
      },
      inputcode(){
         if(this.ruleForm.code==''){
            this.errormassing.code='Please enter the captcha.'
         }else{
            this.errormassing.code=''
         }
      },
      inputcheck(){
         if(this.ruleForm.check==false){
            this.errormassing.check= this.$t('login.cont1')
         }else{
            this.errormassing.check=''
         }
      },
      sendCode(){
         if (this.countdown > 0 || this.isSending) {
         return;
         }

         this.inputemail();
         if(this.errormassing.email == ''){
            this.isSendingloading = true
            this.isSending = true
            emsSend(this.ruleForm.email, 'user_register').then(res => {
               if(res){
                  this.isSendingloading = false
                  this.isSending = false
                  if(res.code == 200 || res.code == 1){
                     this.startCountdown();

                     this.$message.success(res.msg);
                  }
               }else{
                  this.isSendingloading = false
                  this.isSending = false
               }
            })
         }
         
      },
      startCountdown() {
         this.countdown = 60;
         const timer = setInterval(() => {
         this.countdown--;
         if (this.countdown <= 0) {
            clearInterval(timer);
         }
         }, 1000);
      },
      submitForm() {
         this.inputcheck()
         var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
         var nameReg = /^[a-zA-Z][a-zA-Z0-9_]{2,15}$/;
         if(this.ruleForm.email=='' || this.ruleForm.username=='' || this.ruleForm.password=='' || this.ruleForm.code=='' || !emailReg.test(this.ruleForm.email) || !nameReg.test(this.ruleForm.username)|| this.ruleForm.check==false){
            this.inputemail();
            this.inputname();
            this.inputpwd();
            this.inputcode();
         }else{
            this.loading = true
            this.disabled = true
            checkIn('register', this.ruleForm.email, this.ruleForm.password, this.ruleForm.username, this.ruleForm.code).then(res => {
               if(res){
                  if(res.code == 200 || res.code == 1){
                     this.$store.dispatch('getUserInfo',res.data.userInfo)
                     this.$message.success(res.msg);
                     setTimeout(() => {
                        let toPath = this.prevRoute ? this.prevRoute.path : '/';
                        this.$router.push(toPath);
                     }, 3000);
                  }else{
                     this.loading = false
                     this.disabled = false
                  }
               }else{
                  this.loading = false
                  this.disabled = false
               }
            })
         }
        
      },
      googleres(data) {
         setTimeout(() => {
            let toPath = this.prevRoute ? this.prevRoute.path : '/';
            this.$router.push(toPath);
         }, 3000);
      }
   }
}
</script>

<style scoped>
.errorlabel{
   border-bottom: 1px solid #ff3008  !important;
}
.btn{
   width: 100% !important;
}
.code-item{
   display: flex;
}
.register{
   background-color: #272727 !important;
}
.login-three{
   text-align:center;
}
</style>
