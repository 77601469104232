import {
    USER_INFO
} from './mutation-type'
export default {
    //数据处理  edit是mutation里边的方法名
    // add(context, info){
    //     context.commit('edit',info)
    // }
    getUserInfo({commit},info){
        if(info){
            commit(USER_INFO,info)
        }else{
            // console.log(JSON.stringify(window.localStorage));
            if(JSON.stringify(window.localStorage) != "{}" && JSON.stringify(window.localStorage.userInfo) != ""){
                // console.log(1112222)
                // console.log(JSON.stringify(window.localStorage.userInfo));
                let info = JSON.parse(window.localStorage.userInfo);
                // console.log(window.localStorage.userInfo);
                commit(USER_INFO,info)
            }else{
                commit(USER_INFO,"{}")
            }
        }
        // console.log(window.localStorage.userInfo);
        // if(JSON.stringify(window.localStorage.userInfo) == "{}"){
        //     let info = JSON.parse(window.localStorage.userInfo);
        //     commit(USER_INFO,info)

        //     console.log(1111);
        //     let info = JSON.parse(window.localStorage.userInfo);
        //     console.log(window.localStorage.userInfo);
        //     commit(USER_INFO,info)
        // }else{
        //     if(info){
        //         commit(USER_INFO,info)
        //     }
        // }
    }
}