import { request } from "./request";

export function getBrandsList(page, limit, categories, rating, order, keyword, country){
    return request({
        url: '/api/brokers/brokersList',
        params: {
            page,
            limit,
            categories,
            rating,
            order,
            keyword,
            country
        }
    })
}

export function categories(){
    return request({
        url: '/api/brokers/categories',
        params: {
        }
    })
}

export function topBrokersList(page){
    return request({
        url: '/api/brokers/topBrokersList',
        params: {
            page,
        }
    })
}

export function country(){
    return request({
        url: '/api/brokers/country',
        params: {
        }
    })
}

export function brokerserver(query){
    return request({
        url: '/api/brokers/brokerserver',
        params: {
            query
        }
    })
}

export function traderbroker(){
    return request({
        url: '/api/brokers/traderbroker',
        params: {
        }
    })
}