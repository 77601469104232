import { createRouter, createWebHashHistory    } from 'vue-router'
import login from '../views/LoginView.vue'
import register from '../views/RegisterView.vue'

import store from '../store/index'
// const pageTitle = (name: string): string => {
//   return `pagesTitle.${name}`
// }

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Forex Broker Reviews',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta:{
      title: 'Tradervote-Login',
    },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.id) {
          next('/') 
      } else {
          next()
      }
    },
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta:{
      title: 'Tradervote-Register',
    },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.id) {
          next('/') 
      } else {
          next()
      }
    },
  },
  {
    path: '/retrievePassword',
    name: 'RetrievePassword',
    component: () => import('../views/RetrievePassword.vue'),
    meta:{
      title: 'Tradervote-Retrieve Password',
    },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.id) {
          next('/') 
      } else {
          next()
      }
    },
  },
  {
    path: '/topbrokers',
    name: 'topbrokers',
    component: () => import('../views/TopBrokersView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Top Brokers',
    }
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('../views/PromotionView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Pomotion',
    }
  },
  {
    path: '/vlog/index',
    name: 'vlog',
    component: () => import('../views/vlog/VlogView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Vlog',
    }
  },
  {
    path: '/vlog/blog',
    name: 'blog',
    component: () => import('../views/vlog/BlogView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Blog',
    }
  },
  {
    path: '/vlog/detail',
    name: 'vlogdetail',
    component: () => import('../views/vlog/VlogDetailView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Vlog Detail',
    }
  },
  {
    path: '/blog/detail',
    name: 'blogdetail',
    component: () => import('../views/vlog/BlogDetailView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Blog Detail',
    }
  },
  {
    path: '/vlog/personal',
    name: 'personal',
    component: () => import('../views/vlog/PersonalView.vue'),
    meta:{keepAlive:false,title: 'Tradervote-Personal Homepage'},
  },
  {
    path: '/vlog/recommend',
    name: 'recommend',
    component: () => import('../views/vlog/RecommendView.vue'),
    meta:{keepAlive:false,title: 'Tradervote-Recommended users'},
  },
  {
    path: '/vlog/search',
    name: 'recommend',
    component: () => import('../views/vlog/SearchVlogView.vue'),
    meta:{keepAlive:false,title: 'Tradervote-Recommended users'},
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/protocol/ServiceView.vue'),
    meta:{keepAlive:false,title: 'Tradervote-User service agreement'},
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/protocol/PrivacyView.vue'),
    meta:{keepAlive:false,title: 'Tradervote-User privacy policy'},
  },
  {
    path: '/cookie',
    name: 'cookie',
    component: () => import('../views/protocol/CookieView.vue'),
    meta:{keepAlive:false,title: 'Tradervote-Cookie'},
  },
  
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: () => import('../views/vlog/BlogView.vue'),
  //   meta:{
  //     keepAlive:false,
  //     title: 'Tradervote-Vlog',
  //   }
  // },
  {
    path: '/essay',
    name: 'essay',
    component: () => import('../views/NewsDetailView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Pomotion Detail',
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Reviews',
    }
  },
  {
    path: '/listing/:name',
    name: 'listing',
    component: () => import('../views/ListingView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Reviews',
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Detail',
    }
  },
  {
    path: '/comment',
    name: 'comment',
    component: () => import('../views/CommentView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Comment',
    }
  },
  {
    path: '/user/reviews',
    name: 'reviews',
    component: () => import('../views/user/ReviewsView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-My Reviews',
      roles: ['user']
    }
  },
  {
    path: '/user/set',
    name: 'set',
    component: () => import('../views/user/SetView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-My Settings',
      roles: ['user']
    }
  },
  {
    path: '/user/modifypassword',
    name: 'ModifyPassword',
    component: () => import('../views/user/ModifyPassword.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Modify Password',
      roles: ['user']
    }
  },
  {
    path: '/user/vlog',
    name: 'UserVlog',
    component: () => import('../views/user/UserVlog.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-UserVlog',
      roles: ['user']
    }
  },
  {
    path: '/user/creation',
    name: 'creation',
    component: () => import('../views/user/Creation.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Creation',
      roles: ['user']
    }
  },
  {
    path: '/user/notice',
    name: 'notice',
    component: () => import('../views/user/NoticeView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Notice',
      roles: ['user']
    }
  },
  {
    path: '/user/account',
    name: 'UserAccount',
    component: () => import('../views/user/UserAccount.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-UserAccount',
      // roles: ['user']
    }
  },
  {
    path: '/TopChat',
    name: 'TopChat',
    component: () => import('../views/TopChatView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-TopChat',
    }
  },
  {
    path: '/trader/index',
    name: 'traderindex',
    component: () => import('../views/trader/IndexView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Trader Index',
    }
  },
  {
    path: '/trader/account',
    name: 'traderaccount',
    component: () => import('../views/trader/AccountView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Trader Account',
    }
  },
  {
    path: '/trader/bindaccount',
    name: 'traderbinding',
    component: () => import('../views/trader/BindView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Trader Binding',
      // roles: ['user']
    }
  },
  {
    path: '/trader/apply',
    name: 'applybroker',
    component: () => import('../views/trader/ApplyView.vue'),
    meta:{
      keepAlive:false,
      title: 'Tradervote-Apply Broker',
      // roles: ['user']
    }
  },
  // {
  //   path: '/user',
  //   name: 'user',
  //   component: () => import('../views/user/SetView.vue'),
  //   meta:{keepAlive:false, roles: ['user']},
  //   children:[
  //     {
  //       path: '/reviews',
  //       name: 'reviews',
  //       component: () => import('../views/user/ReviewsView.vue'),
  //       meta:{keepAlive:false, roles: ['user']},
  //     },
  //     {
  //       path: '/set',
  //       name: 'set',
  //       component: () => import('../views/user/SetView.vue'),
  //       meta:{keepAlive:false, roles: ['user']},
  //     }
  // ]  
  // },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta:{keepAlive:false}  
  },
  {
    path: '/texts',
    name: 'texts',
    component: () => import(/* webpackChunkName: "about" */ '../views/TextsView.vue'),
    meta:{keepAlive:false}  
  },
  {
      path: '/:path(.*)*',
      redirect: '/404',
  },
  {
      // 404
      path: '/404',
      name: 'notFound',
      component: () => import('../views/404.vue'),
      meta:{
        keepAlive:false,
        title: 'Tradervote-404'
      }  
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
});


router.beforeEach((to, from, next) => {
  if (to.meta.title) { 
    document.title = to.meta.title;
  }
  if (from.name !== null) {
    sessionStorage.setItem('prevRoute', JSON.stringify(from.fullPath))
  }

  let userRole = ''
  
  
  if (store.state.userInfo.id) {
    userRole = 'user'
  }


  if (to.meta.roles) {
    if (to.meta.roles.includes(userRole)) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})
const goBackToPrevRoute = () => {
  const prevRoute = JSON.parse(sessionStorage.getItem('prevRoute') || '""')
  router.push(prevRoute)
  sessionStorage.removeItem('prevRoute')
}
export default router
