import CryptoJS from 'crypto-js';
 
export default {
    encrypt(data) {
        // 将数据转换为JSON格式
        const jsonData = JSON.stringify(data);
        
        // 设定加密算法、向量和密钥等信息
        const key = "your_secret_key";
        const iv = "your_initialization_vector";
        const algorithm = "AES-CBC";
        
        // 进行加密操作
        let encrypted = CryptoJS.AES.encrypt(jsonData, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        
        return encrypted.toString();
    },
    
    decrypt(ciphertext) {
        // 设定解密算法、向量和密钥等信息
        const key = "your_secret_key";
        const iv = "your_initialization_vector";
        const algorithm = "AES-CBC";
        
        try {
            // 进行解密操作
            let bytes = CryptoJS.AES.decrypt(ciphertext, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            
            // 返回解密后的结果
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            console.log("Decryption failed", error);
            throw new Error('Failed to decrypt');
        }
    }
}