import axios from "axios";
import { ElMessage, ElLoading } from 'element-plus'
import CryptoJS from 'crypto-js';

import store from '../store/index'
import i18n from '../lang/index.js'
export function request(config) {
    let baseurl = window.location.host;
    // console.log(baseurl);
    // if(baseurl=="fx.top" || baseurl=="www.fx.top" || baseurl=="16.162.220.57" || baseurl=="fxtop.vp98.com"){
    //     baseurl = "/index.php"
    // }else{
    //     baseurl = "http://16.162.220.57/index.php"
    //     // baseurl = "https://fx.top/index.php"
    // }
    if(baseurl == 'localhost:8080'){
        baseurl = "http://16.162.220.57/index.php"
    }else{
        baseurl = "/index.php"
    }
    const instance = axios.create({
        baseURL: baseurl,
        // baseURL: 'http://www1.fx.com/index.php',
        timeout: 10000,
    })


    let needLoadingRequestCount = 0;
    let loading;
    function startLoading() {
        loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        });
    }
    function endLoading() {
        setTimeout(function () {
            if (loading) loading.close();
        }, 500);
    }
    function showFullScreenLoading() {
        if (needLoadingRequestCount === 0) {
            startLoading();
        }
        needLoadingRequestCount++;
    }
    function tryHideFullScreenLoading() {
        if (needLoadingRequestCount <= 0) return;
        needLoadingRequestCount--;
        if (needLoadingRequestCount === 0) {
            endLoading();
        }
    }


    
    instance.interceptors.request.use(config => {
        if (config.headers.showLoading == "true") {
            showFullScreenLoading();
        }
        // console.log(config);
        const time = Date.now();
        const str = time+'20240131'; 
        const encryptedStr = CryptoJS.MD5(str).toString();
        const lang = i18n.global.locale.value;

        config.headers['think-lang'] = (lang === 'zh' ? 'zh-cn' : 'en');
        config.headers['ba-user-sign'] = 'ssign='+encryptedStr+'&mtime='+time;
        if (store.state.userInfo.id) {
            
            config.headers['ba-user-token'] = store.state.userInfo.token
        }
        return config;
    }, err => {
        if (needLoadingRequestCount > 0) {
            tryHideFullScreenLoading();
        }
        ElMessage.error(i18n.global.t('Request timeout'))
    })

   
    instance.interceptors.response.use(res => {

        if (needLoadingRequestCount > 0) {
            tryHideFullScreenLoading();
        }
        if(res){
            if (res.data.code != 200 && res.data.code != 1) {
                if(res.data.code == 402 || res.data.code == 302 || res.data.code == 409){
                    store.commit('addUserInfo',{})
                }
                ElMessage.error(res.data.msg)
                return false;
            } else {
                return res.data;
            }
        }else{
            // console.log(11111);
            ElMessage.error(i18n.global.t('Network error'))
            return {code:0,data:{}}
        }
    }, err => {
        // loading
        if (needLoadingRequestCount > 0) {
            tryHideFullScreenLoading();
        }
        ElMessage.error(i18n.global.t('Request timeout'))
        // console.log(err);
        return {code:0,data:{}}
        
    })

    return instance(config)
}

// export function request(config){
//     return new Promise((resolve, reject) => {
//         const instance = axios.create({
//             baseURL: 'http://www1.fx.com/index.php',
//             timeout: 5000,
//         })
//         
//         instance(config)
//         .then(res => {
//             resolve(res);
//         })
//         .catch(err => {
//             reject(err);
//         })
//     })
// }


// export function request(config,success,failure){
//     const instance = axios.create({
//         baseURL: 'http://www1.fx.com/index.php',
//         timeout: 5000,
//     })
//     
//     instance(config)
//     .then(res => {
//         success(res);
//     })
//     .catch(err => {
//         failure(err);
//     })
// }