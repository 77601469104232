<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <Header class="headerview" v-show="(path!='/login' && path!='/register' && path!='/retrievePassword')">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            {{ $t('language') }}
        </a>
        <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
          <a href="javascript:;" class="nav-link" @click="changeLanguage('en')">English</a>
          <a href="javascript:;" class="nav-link" @click="changeLanguage('zh')">简体中文</a>
        </div>
    </li>
    <template v-if="$store.state.userInfo.id">
        
        <li class="nav-item dropdown user">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <img alt="Generic placeholder image"  :src="$store.state.userInfo.avatar.avatar"
                    class="nav-osahan-pic rounded-pill"> 
                    {{ $store.state.userInfo.username }}
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
                <router-link to="/user/reviews" class="dropdown-item">My Reviews</router-link>
                <router-link to="/user/set" class="dropdown-item">My Settings</router-link>
                <router-link to="/user/vlog" class="dropdown-item">My Blog</router-link>
                <router-link to="/user/account" class="dropdown-item">{{ $t('Verify Account') }}</router-link>
                <router-link to="/user/modifypassword" class="dropdown-item">{{ $t('Modify Password') }}</router-link>
                <router-link to="" class="dropdown-item">Help</router-link>
                <a href="javascript:;" class="dropdown-item" @click="logoutcheck">{{ $t('Log Out') }}</a>
            </div>
        </li>
        <li class="nav-item index">
          <template v-if="noticecount">
            <el-badge is-dot class="item">
              <router-link to="/user/notice" class="nav-link"><el-icon size="20"><Bell /></el-icon></router-link>
            </el-badge>
          </template>
          <template v-else>
              <router-link to="/user/notice" class="nav-link"><el-icon size="20"><Bell /></el-icon></router-link>
          </template>
        </li>
    </template>
    <template v-else>
      
        <li class="nav-item">
            <RouterLink to="/login" class="nav-link" style="padding:.5rem 1rem  !important;">{{ $t('Sign in') }}</RouterLink>
        </li>
        <li class="nav-item">
            <RouterLink to="/register" class="nav-link" style="border: 1px solid;padding:.5rem 1rem  !important;">{{ $t('Sign up') }}</RouterLink>
        </li>
    </template>
  </Header>
  <template v-if="(path!='/login' && path!='/register' && path!='/retrievePassword')">
    <router-view :style="{ 'margin-top': headerHeight + 'px' }" ></router-view>
  </template>
  <template v-else>
    <router-view></router-view>
  </template>
  
  <Footer v-show="(path!='/login' && path!='/register' && path!='/retrievePassword')"/>
</template>

<script>
// @ is an alias to /src
// import $ from 'jquery'
import $ from '@/assets/js/Jquery.js'
import '@/assets/vendor/bootstrap/css/bootstrap.min.css'
import '@/assets/vendor/fontawesome/css/all.min.css'
import '@/assets/vendor/icofont/icofont.min.css'
import '@/assets/vendor/select2/css/select2.min.css'
import '@/assets/css/osahan.css'
import '@/assets/vendor/owl-carousel/owl.carousel.css'
import '@/assets/vendor/owl-carousel/owl.theme.css'
import '@/assets/vendor/icon/iconfont.css'
import '@/assets/vendor/icon/iconfont.js'
// import $ from '@/assets/vendor/jquery/jquery-3.3.1.slim.min.js'
import '@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
import '@/assets/vendor/select2/js/select2.min.js'
import owlCarousel from '@/assets/vendor/owl-carousel/owl.carousel.js'
// import '@/assets/js/custom.js'
import '@/assets/icon/iconfont.css'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {logout, noticecont} from '@/network/user.js'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      path: '',
      windowHeight: 0,
      headerHeight: 0,
      noticecount: false,
      intervalId: null,
    }
  },
  mounted(){
    // console.log(JSON.stringify({id:1}));
    this.$store.dispatch('getUserInfo')
    // console.log(this.$store.state.userInfo.id)
    // console.log(this.$store.state.userInfo.length);
    this.calculateHeaderHeight();
    window.addEventListener('resize', () => {
      this.calculateHeaderHeight();
    })

    
  },
  watch: {
    $route: {
      handler: function(to, from){
        this.path = to.path
        this.calculateHeaderHeight();
      },
      deep: true
    },
  },
  created() {

    if(window.localStorage.userInfo != "{}"){
      this.getnoticecont();
      this.intervalId = setInterval(this.getnoticecont, 60000);
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods:{
    calculateHeaderHeight() {
      this.headerHeight = $(".headerview nav").outerHeight(true)
    },
    logoutcheck() {
      logout().then(res => {
        this.$store.commit('addUserInfo', {})
        // setTimeout(() => {
          window.location.reload() 
        // }, 2000);
      })
    },
    changeLanguage(lang) {
    // console.log('Selected language:',  i18n.global.locale.value);
      // this.$i18n.locale = (this.$i18n.locale === 'en' ? 'zh' : 'en');
      if(lang != this.$i18n.locale){
        this.$i18n.locale = lang
        localStorage.setItem("lang",this.$i18n.locale)
        location.reload();
      }
    },
    getnoticecont(){
        noticecont().then(res => {
          this.noticecount = res.data
        })
    }
    
  }
}
</script>
<style scoped>

</style>
